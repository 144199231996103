<template>
  <div class="bg-indigo">
    <section class="pt-[16vw] md:pt-[5vw] font-work">
      <SupportHero>
        <SupportButton @click="handleSelect('general')"
                       :selected="type === 'general'"
        >
          General
        </SupportButton>
        <SupportButton @click="handleSelect('game')"
                       :selected="type === 'game'"
        >
          Game specific
        </SupportButton>
      </SupportHero>

      <ClientOnly>
        <SupportGeneral v-if="type === 'general'"
                        :categories="content.general"
                        ref="generalContainer"
        >
          <SupportCard />
        </SupportGeneral>
        <SupportGameSpecific v-if="type === 'game'"
                             v-model:game="game"
                             v-model:platform="platform"
                             :games="content.games"
                             ref="gameContainer"
        >
          <SupportCard />
        </SupportGameSpecific>
      </ClientOnly>
    </section>
  </div>
</template>

<script setup lang="ts">
import { useNuxtApp, type ComponentPublicInstance } from '#imports';
import { nextTick, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const { $lenisScroll } = useNuxtApp();

type TemplateProps = {
    content: any
}

defineProps<TemplateProps>();

const { $event } = useNuxtApp();
const route = useRoute();
const router = useRouter();

const type = ref(route.query['t'] ?? null);
const game = ref(route.query['g'] ?? null);
const platform = ref(route.query['p'] ?? null);
const generalContainer = ref<ComponentPublicInstance>(null);
const gameContainer = ref<ComponentPublicInstance>(null);

onMounted(() => {
  nextTick(() => {
    // check if there is a hash anchor in the url, if so, we scroll to it instead of to the section
    if (route.hash) $lenisScroll(route.hash);
    else if (type.value === 'general' && generalContainer.value?.$el) $lenisScroll(generalContainer.value.$el);
    else if (type.value === 'game' && gameContainer.value?.$el && !game.value && !platform.value) $lenisScroll(gameContainer.value.$el);
  });
});

function handleSelect(value: string) {
  $event('support-set-type', { props: { value } });

  type.value = value;

  nextTick(() => {
    if (generalContainer.value && value === 'general') {
      $lenisScroll(generalContainer.value.$el);
    }

    if (gameContainer.value && value === 'game') {
      $lenisScroll(gameContainer.value.$el);
    }
  });
}

/**
 * If we select general, we remove game and platform from the query
 *
 * If we select game and game or platform is set (from before, we reinstate them in the query)
 */
watch(type, value => {
  if (value === 'general') {
    delete route.query['g'];
    delete route.query['p'];
    game.value = null;
    platform.value = null;
  } else {
    if (game.value) {
      route.query['g'] = game.value;
    }

    if (platform.value) {
      route.query['p'] = platform.value;
    }
  }

  router.replace({
    query: {
      ...route.query,
      ['t']: value
    }
  });
});

watch(game, value => {
  if (value) {
    $event('support-set-game', { props: { value: value as string } });
    
    delete route.query['p'];
    platform.value = null;
  
    router.replace({
      query: {
        ...route.query,
        ['g']: value
      }
    });
  }
});

watch(platform, value => {
  if (value) {
    $event('support-set-platform', { props: { value: value as string } });

    router.replace({
      query: {
        ...route.query,
        ['p']: value
      }
    });
  }
});
</script>